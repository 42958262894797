export const environment = {
  production: true,
  license: 'sonar',
  sentry: {
    dsn: null,
  },
  googleAnalytics: 'G-V1WP5D94XN',
  allowSubmissionsToViewOtherSubmissions: false,
  numberOfAcceptedOrganisationCallsInMenu: 5,
  collectiveRightsManagementEnabled: false,
};
